export default {
  // common
  'common--monochrome-max': '#222B46',
  'common--monochrome-zero': '#ffffff',
  'common--monochrome-zero-up': '#F8FAFD',
  'common--monochrome-zero-down': '#E6EDF8',
  'common--primary': '#2D5FB4',
  'common--primary-up': '#4175CD',
  'common--primary-down': '#1F54AF',
  'common--primary-medium': '#C0DCFE',
  'common--accent': '#1E3787',
  'common--accent-up': '#4678CE',
  'common--accent-intensive': '#4FB9D2',
  'common--accent-intensive-down': '#4092A5',
  'common--additional': '#DBEAFC',
  'common--additional-low': '#EEF6FF',
  'common--additional-up': '#E4F1FF',
  'common--additional-down': '#D0E2F8',
  'common--negative': '#F95D5D',
  'common--negative-up': '#FF7171',
  'common--negative-down': '#F25252',
  'common--negative-medium': '#F7C9C9',
  'common--negative-low': '#FFEFEF',
  'common--positive': '#40A55C',
  'common--positive-up': '#4AB568',
  'common--positive-down': '#369B52',
  'common--positive-intensive-up': '#4CDF75',
  'common--positive-intensive': '#B6DE00',
  'common--positive-medium': '#B8E3C4',
  'common--positive-low': '#E8FBED',
  'common--secondary': '#FFB900',
  'common--secondary-up': '#FFC733',
  'common--secondary-down': '#FCA702',
  'common--secondary-medium': '#FFE0A3',
  'common--secondary-low': '#FFF6DE',
  'common--secondary-intensive': '#EB9B00',
  'common--secondary-intensive-up': '#FFA800',
  'common--monochrome-medium': '#8794AA',
  'common--monochrome-low': '#F0F4FA',
  'common--monochrome-intensive': '#4E5D76',
  'common--monochrome-intensive-up': '#8B91A2',
  'common--disabled': '#D2DCEF',
  // system-messages
  'system-messages--positive': '#E8FBED',
  'system-messages--positive2': '#40A55C',
  'system-messages--negative': '#FFEFEF',
  'system-messages--negative2': '#F95D5D',
  'system-messages--pending': '#FFF6DE',
  'system-messages--pending2': '#EB9B00',
  'system-messages--info': '#E4F1FF',
  'system-messages--review': '#EEFADA',
  'system-messages--review2': '#88993B',
  'system-messages--rejected': '#F0F4FA',
  'system-messages--rejected2': '#8794AA',
  // icons
  'icons--default': '#222B46',
  'icons--additional': '#DBEAFC'
}
