<template>
  <button
    class="button"
    :class="[
      {
        'is-icon': icon,
        'is-round': round,
        'is-loading': loading,
        'is-flat': flat
      },
      !flat && `is-${view}`,
      `is-${size}`
    ]"
    :cy-data="cyData"
    v-bind="otherArgs"
    @click="onBntClick($event)"
  >
    <AppIcon
      :class="prefixIconClass"
      v-if="prefixIcon"
      :name="prefixIcon"
    />
    <slot>{{ textC }}</slot>
    <AppIcon
      :class="suffixIconClass"
      v-if="suffixIcon"
      :name="suffixIcon"
    />
  </button>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'
import AppIcon from './AppIcon.vue'
import type { TSizeAppButton, TViewAppButton } from '../models/Buttons'
import { useRouter } from 'vue-router'

interface IOtherArgs {
  class?: object
  disabled?: boolean
}

const props = withDefaults(
  defineProps<{
    text?: string | null | boolean
    view?: TViewAppButton
    size?: TSizeAppButton
    icon?: boolean
    round?: boolean
    flat?: boolean
    disabled?: boolean
    loading?: boolean
    static?: boolean
    prefixIcon?: string
    prefixIconClass?: string
    suffixIcon?: string
    suffixIconClass?: string
    to?: unknown
    cyData?: string
    closeModal?: boolean // Если установлен пропс и если кнопка в модалке, то нажатие закроет эту модалку
    isBlank?: boolean
  }>(),
  {
    view: 'primary',
    size: 'normal',
    icon: false,
    round: false,
    flat: false,
    disabled: false,
    loading: false,
    static: false,
    closeModal: false
  }
)

const emit = defineEmits<{
  click: [event: Event]
}>()

/* ------------------------------------------------------------------------------------------------------------------ */
const router = useRouter()

const textC = computed(() => {
  if (typeof props.text === 'boolean') {
    return undefined
  }

  return props.text
})

const closeModalFn = inject<(() => void) | undefined>(
  'AppModalClose',
  undefined
)

const otherArgs = computed(() => {
  const otherArgs: IOtherArgs = {}

  if (props.disabled && props.to) {
    otherArgs.class = { 'is-disabled': props.disabled }
  }

  if (props.disabled && !props.to) {
    otherArgs.disabled = props.disabled
  }

  return otherArgs
})

const isExternalLink = computed(() => {
  return typeof props.to === 'string' && props.to.startsWith('http')
})

function onBntClick(event: Event) {
  if (props.loading) {
    event.stopPropagation()
    event.preventDefault()

    return
  }

  if (props.to) {
    if (isExternalLink.value && typeof props.to === 'string') {
      window.open(props.to, '_blank')
    } else {
      if (props.isBlank) {
        const path = router.resolve(props.to)

        window.open(path.href, '_blank')
      } else {
        router.push(props.to)
      }
    }
  }

  emit('click', event)

  if (props.closeModal && closeModalFn) {
    closeModalFn()
  }
}
</script>
