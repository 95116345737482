import type { TCurrency } from '../models/Currency'

export function isUsdFamily(symbol: TCurrency) {
  return ['USD.R', 'USD.K'].includes(symbol)
}

export function isRubFamily(symbol: TCurrency) {
  return ['RUB.R', 'RUB.K'].includes(symbol)
}

export function symbolToURLsymbol(symbol: TCurrency) {
  return symbol.replace('.', '_')
}

export function isSubCurrency(symbol: TCurrency) {
  return isUsdFamily(symbol) || isRubFamily(symbol)
}

export function getSign(value: number) {
  if (value > 0) {
    return '+'
  }

  if (value < 0) {
    return '–'
  }

  return ''
}

export function getSignClass(
  value: number,
  plus: string,
  minus: string,
  neutral?: string
) {
  if (value > 0) {
    return plus
  }

  if (value < 0) {
    return minus
  }

  return neutral || 'text-gray-dark'
}
