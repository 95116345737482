import { httpClient } from '~common/plugins/httpClient'
import type { IInfoBlock } from '~common/models/InfoBlocks'
import type { IResponseResult } from '../models/Api'

export default {
  async getInfoblock<T extends object>(domain: string, key: string) {
    const response = await httpClient.get<IInfoBlock<T>>('/public/infoblocks', {
      params: { domain, key }
    })

    return response.data
  },

  async putInfoblock(domain: string, payload: IInfoBlock) {
    const result = await httpClient.put<IResponseResult>(
      '/admin/infoblocks',
      payload,
      { params: { domain } }
    )

    return result.data
  }
}
