import { defineStore } from 'pinia'
import { computed, reactive, ref, toRaw, watch } from 'vue'
import infoblocksApi from '../api/infoblocks.api'
import type {
  IInfoBlockAcquiring,
  IInfoBlockApiSettings,
  IInfoBlockBuySell,
  IInfoBlockDeposit,
  IInfoBlockKYC,
  IInfoBlockPromo,
  IInfoBlockTechnicalWorks
} from '../models/InfoBlocks'
import { useI18n } from '../composables/useI18n'
import useNotify from '../composables/notify/useNotify'

export default defineStore('infoBlocks', () => {
  const { notifySuccess, notifyError } = useNotify()

  const { currentLocale, t } = useI18n()

  const domain = ref<string>()

  const buysel = useInfoBlockVal<IInfoBlockBuySell>('buysel')
  const kyc = useInfoBlockVal<IInfoBlockKYC>('kyc')
  const promo = useInfoBlockVal<IInfoBlockPromo>('promo')
  const apiSettings = useInfoBlockVal<IInfoBlockApiSettings>('apiSettings')
  const deposit = useInfoBlockVal<IInfoBlockDeposit>('deposit')
  const acquiring = useInfoBlockVal<IInfoBlockAcquiring>('acquiring')

  const technicalWorks =
    useInfoBlockVal<IInfoBlockTechnicalWorks>('technicalWorks')

  function useInfoBlockVal<T extends object>(key: string) {
    let doVal = resetVal()

    const isLoading = ref(false)

    let isInit = false

    watch(domain, () => {
      isInit = false
      doVal = resetVal()
    })

    const object = computed(() => {
      try {
        if (!isInit && domain.value) {
          isInit = true
          isLoading.value = true

          infoblocksApi.getInfoblock<T>(domain.value, key).then(({ value }) => {
            Object.assign(doVal, value)
          })
        }
      } catch (_e) {
        /**/
      } finally {
        isLoading.value = false
      }

      return doVal
    })

    function resetVal() {
      return reactive<T>({} as T)
    }

    async function updateValue() {
      try {
        if (!domain.value) {
          return
        }

        isLoading.value = true

        await infoblocksApi.putInfoblock(domain.value, {
          key,
          value: toRaw(doVal)
        })

        notifySuccess(t('common.state.settingsSaved'))
      } catch (e) {
        notifyError(e)
      } finally {
        isLoading.value = false
      }
    }

    return {
      object,
      updateValue,
      isLoading
    }
  }

  function getLangDep(obj: Record<string, unknown>, key: string) {
    return computed<string | undefined>(() => {
      return (obj[`${key}_${currentLocale.value}`] ||
        obj[`${key}_en`]) as string
    })
  }

  function setDomain(value?: string) {
    domain.value = value
  }

  return {
    getLangDep,
    buysel,
    kyc,
    promo,
    apiSettings,
    deposit,
    acquiring,
    technicalWorks,
    setDomain
  }
})
